<template>
  <div
    class="card mb-5"
    :class="isLoading?'sec-saving':''"
  >
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            Documents
          </h4>
          <cp-edit-buttons
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="saveChanges"
          >
            <template slot="leftToButtons">
              <cp-button
                class="mr-3"
                icon="ios-add"
                variant="primary"
                @click="showAddDocModal"
              >
                Add document
              </cp-button>
            </template>
          </cp-edit-buttons>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        :items="legalSignerByIdInfo.documents"
        :url-params="queryParams"
        :fields="documentsFields"
        class="card-table"
      >
        <template
          slot="image"
          slot-scope="{item}"
        >
          <img
            class="ui-w-40 d-block link"
            :src="getImageIcon(item)"
            style="cursor: pointer"
            alt="Document image"
            @click="showPreviewDocModal(item)"
          >
        </template>
        <template
          slot="docType"
          slot-scope="rowData"
        >
          <span v-if="!editMode">
            {{ getDocType(rowData.item.docType) }}
          </span>
          <cp-select
            v-else
            v-model="model[rowData.index].docType"
            :options="getDocumentTypesList()"
          />
        </template>
        <template
          slot="status"
          slot-scope="{ item, index }"
        >
          <b-badge
            v-if="!editMode"
            :class="getStatusColor(item)"
          >
            {{ item.verificationStatus }}
          </b-badge>
          <cp-select
            v-else
            v-model="model[index].verificationStatus"
            :options="statusOptions"
          />
        </template>
        <template
          slot="createdAt"
          slot-scope="{item}"
        >
          {{ item.createDate | dateFilter }}
        </template>
        <template
          slot="actions"
          slot-scope="{item}"
        >
          <div class="d-flex">
            <cp-button
              icon="ios-arrow-round-down"
              variant="outline-primary"
              :disabled="downloadingDocuments[item.documentId]"
              :is-loading="downloadingDocuments[item.documentId]"
              @click="downloadDocument(item)"
            >
              Download
            </cp-button>
            <cp-button
              icon="ion ion-ios-trash"
              variant="default ml-3"
              :disabled="documetsCount < 2"
              @click="showDeleteModal(item)"
            >
              <span
                v-if="documetsCount < 2"
                v-b-tooltip.hover="true"
                v-b-tooltip.hover.left
                :title="(documetsCount < 2) ?'At least one document must be associated':''"
              >
                Delete
              </span>
              <span
                v-if="documetsCount > 1"
              >
                Delete
              </span>
            </cp-button>
          </div>
        </template>
      </b-table>
    </div>


    <add-document-modal
      ref="addDocModal"
      :categories="categories"
      @docIsAdded="refreshTable"
    />

    <preview-document-modal
      ref="previewDocModal"
      :document-id="documentId"
      :investor-id="queryParams.investorId"
      :file-type="fileType"
    />

    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('investorDetails.removeModal.title', [$t('investorDetails.documents.label.document')])"
      @onOk="deleteDocument"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import documents from './options';

import PreviewDocumentModal from './modals/preview-document-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import AddDocumentModal from './modals/add-document-modal';
import staticPathGeneration from '~/mixins/static-path-generation';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { CpSelect } from '@/components/common/standalone-components/inputs';
import CpEditableMixin from '@/mixins/editable-component';
import CpEditButtons from '@/components/common/edit-buttons';

export default {
  name: 'LegalSignerDocuments',
  components: {
    CpButton,
    AddDocumentModal,
    PreviewDocumentModal,
    CpConfirmModal,
    CpSelect,
    CpEditButtons,
  },
  mixins: [staticPathGeneration, CpEditableMixin],
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      queryParams: {
        investorId: this.$route.params.investorId,
        legalSignerId: this.$route.params.legalSignerId,
      },
      documentId: null,
      fileType: '',
      downloadingDocuments: {},
      deleteItem: null,
      isLoading: false,
      documentsFields: documents.fields,
      confirmModalText: this.$t(
        'investorDetails.removeModal.message',
        [this.$t('investorDetails.documents.label.document')],
      ),
      statusOptions: documents.statuses,
      model: [],
    };
  },
  computed: {
    ...mapState('securitizeId', ['legalSignerByIdInfo']),
    documetsCount() {
      return this.legalSignerByIdInfo.documents.length;
    },
    getImageIcon() {
      return ({ thumbnail, fileType }) => (
        fileType === 'application/pdf'
          ? this.getImagePath('fallback-pdf.jpg')
          : thumbnail || this.getImagePath('fallback-image.jpg')
      );
    },
  },
  methods: {
    ...mapActions('securitizeId', ['getSecuritizeILegalSignerDocumentById',
      'deleteSecuritizeIdLegalSignerDocumentById',
      'getSecuritizeIdLegalSignerById',
      'updateInvestorLegalSignerDocumentsStatus',
    ]),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    async refreshTable() {
      this.isLoading = true;
      await this.getSecuritizeIdLegalSignerById({ params: { ...this.queryParams } });
      this.isLoading = false;
    },
    showDeleteModal({ documentId }) {
      this.deleteItem = documentId;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    showPreviewDocModal({ documentId, fileType }) {
      this.documentId = documentId;
      this.fileType = fileType;
      this.$refs.previewDocModal.$refs.previewDocument.show();
    },
    showAddDocModal() {
      this.$refs.addDocModal.$refs.uploadDocument.show();
    },
    deleteDocument() {
      this.deleteSecuritizeIdLegalSignerDocumentById({
        ...this.queryParams,
        documentId: this.deleteItem,
      }).then(this.refreshTable);
    },
    downloadDocument({ documentId }) {
      this.downloadingDocuments[documentId] = true;
      this.getSecuritizeILegalSignerDocumentById({
        documentId,
        ...this.queryParams,
      }).then(
        ({ url }) => {
          if (url) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = url.split('/').pop();
            link.name = url.split('/').pop();
            link.href = url;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER(this.$t('investorDetails.documents.message.downloadFailure'));
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[documentId];
      });
    },
    getStatusColor(document) {
      let statusColor = 'badge-outline-dark';
      switch (document.verificationStatus) {
        case 'pending':
          statusColor = 'badge-outline-primary';
          break;
        case 'not-verified':
          statusColor = 'badge-outline-danger';
          break;
        case 'manual-review':
          statusColor = 'badge-outline-dark';
          break;
        case 'verified':
          statusColor = 'badge-outline-success';
          break;
        default:
      }
      return `${statusColor}`;
    },
    changeModeProxy() {
      this.model = this.legalSignerByIdInfo.documents
        .map(({ documentId, verificationStatus, docType }) => ({ documentId, verificationStatus, docType }));
      this.changeMode();
    },
    saveChanges() {
      const documentsData = this.model.reduce((acc, item, index) => {
        const doc = this.legalSignerByIdInfo.documents[index];
        const updatedDoc = { ...item };

        if (updatedDoc.verificationStatus === doc.verificationStatus) {
          delete updatedDoc.verificationStatus;
        }
        if (updatedDoc.docType === doc.docType) {
          delete updatedDoc.docType;
        }

        return [...acc, updatedDoc];
      }, []);
      this.updateInvestorLegalSignerDocumentsStatus({
        investorId: this.queryParams.investorId,
        legalSignerId: this.queryParams.legalSignerId,
        documents: documentsData,
      })
        .then(this.refreshTable)
        .finally(this.changeModeProxy);
    },
    getDocType(docType) {
      const { signerType, entityType } = this.legalSignerByIdInfo;
      if (signerType === 'entity' && entityType) {
        return this.findType(this.categories.entityDocumentTypes, docType);
      }
      return this.findType(this.categories.documentTypeIndividualFull, docType);
    },
    getDocumentTypesList() {
      const { signerType } = this.legalSignerByIdInfo;
      return (signerType === 'entity' ? this.categories.entityDocumentTypes : this.categories.documentTypeIndividualFull)
        .map(({ text, value }) => ({ text, value: value.docType }));
    },
    findType(types, type) {
      const find = ({ value }) => value.docType === type;
      const found = types.find(find);
      if (!found) return type;
      return found.text;
    },
  },
};
</script>
<style scoped>
    .sec-saving::after{
      content:"";
      position: absolute;
      width:100%;
      height:100%;
      background:#fff;
      opacity: 0.4;
    }
</style>
